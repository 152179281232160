<div name="love" class="container-fluid justify-content-start">
  <div class="d-flex sticky-top w-100">
    <div class="col-12 d-flex justify-content-start">
      <a class="header-link" [routerLink]="'/home'">
        home
      </a>
    </div>
  </div>
  <div id="content-device">
    <div class="d-flex  justify-content-center p-2" *ngFor="let item of artModel.items let i = index">
      <a [routerLink]="[item.showUrl]">
        <img class="img-fluid mx-auto d-block" src="{{item.fullSizeUrl}}">
      </a>
    </div>
  </div>
  <div id="content-desktop">
    <div class="d-flex flex-row col-md-10 mt-2 justify-content-center">
      <div class="col-md-1">
      </div>
      <div class="col-md-11">
        <a>
          <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('Aspekter_resize').thumbUrl}}"
            width="{{artModel.getItem('Aspekter_resize').width}}" height="{{artModel.getItem('Aspekter_resize').height}}">
        </a>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="col-md-3 d-flex justify-content-end align-items-center me-3">
        <a [routerLink]="[artModel.getItem('hus_resize').showUrl]">
          <img src="{{artModel.getItem('hus_resize').thumbUrl}}" width="{{artModel.getItem('hus_resize').width}}"
            height="{{artModel.getItem('hus_resize').height}}">
        </a>
      </div>
      <div class="col-md-9">
        <div class="d-flex flex-row mt-4">
          <div class="col-md-3">
            <div class="d-flex justify-content-center ms-5 mt-3">
              <a [routerLink]="[artModel.getItem('s15a_resize').showUrl]">
                <img src="{{artModel.getItem('s15a_resize').thumbUrl}}"
                  width="{{artModel.getItem('s15a_resize').width}}" height="{{artModel.getItem('s15a_resize').height}}">
              </a>
            </div>
            <div class="d-flex m-4 mt-5">
              <a [routerLink]="[artModel.getItem('Spredning_resize').showUrl]">
                <img src="{{artModel.getItem('Spredning_resize').thumbUrl}}"
                  width="{{artModel.getItem('Spredning_resize').width}}"
                  height="{{artModel.getItem('Spredning_resize').height}}">
              </a>
            </div>
          </div>
          <div class="d-flex flex-column col-md-9">
            <div class="d-flex justify-content-center">
              <div class="col-md-5 me-3 d-flex justify-content-center">
                <a [routerLink]="[artModel.getItem('s15b_resize').showUrl]">
                  <img src="{{artModel.getItem('s15b_resize').thumbUrl}}"
                    width="{{artModel.getItem('s15b_resize').width}}" height="{{artModel.getItem('s15b_resize').height}}">
                </a>
              </div>
              <div class="col-md-7 pl-2 mt-3 d-flex justify-content-start">
                <a [routerLink]="[artModel.getItem('s15c_resize').showUrl]">
                  <img src="{{artModel.getItem('s15c_resize').thumbUrl}}"
                    width="{{artModel.getItem('s15c_resize').width}}" height="{{artModel.getItem('s15c_resize').height}}">
                </a>
              </div>
            </div>
            <div class="d-flex justify-content-start m-2 ps-3 ms-5 mt-5">
              <a [routerLink]="[artModel.getItem('s9_resize').showUrl]">
                <img src="{{artModel.getItem('s9_resize').thumbUrl}}" width="{{artModel.getItem('s9_resize').width}}"
                  height="{{artModel.getItem('s9_resize').height}}">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex pt-3">
      <div class="col-md-5">
        <div class="d-flex me-5 pe-1 justify-content-end">
          <a [routerLink]="[artModel.getItem('s28a_resize').showUrl]">
            <img src="{{artModel.getItem('s28a_resize').thumbUrl}}" width="{{artModel.getItem('s28a_resize').width}}"
              height="{{artModel.getItem('s28a_resize').height}}">
          </a>
        </div>
        <div class="d-flex mt-5 me-5 pe-2 justify-content-center">
          <a [routerLink]="[artModel.getItem('s28b_resize').showUrl]">
            <img src="{{artModel.getItem('s28b_resize').thumbUrl}}" width="{{artModel.getItem('s28b_resize').width}}"
              height="{{artModel.getItem('s28b_resize').height}}">
          </a>
        </div>
        <div class="d-flex mt-5 me-5 pe-5 justify-content-center">
          <div class="pl-4">
            <a [routerLink]="[artModel.getItem('s28c_resize').showUrl]">
              <img src="{{artModel.getItem('s28c_resize').thumbUrl}}" width="{{artModel.getItem('s28c_resize').width}}"
                height="{{artModel.getItem('s28c_resize').height}}">
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="d-flex justify-content-center me-5 pe-2 mt-5 pt-5">
          <a [routerLink]="[artModel.getItem('monsteret_resize').showUrl]">
            <img src="{{artModel.getItem('monsteret_resize').thumbUrl}}"
              width="{{artModel.getItem('monsteret_resize').width}}"
              height="{{artModel.getItem('monsteret_resize').height}}">
          </a>
        </div>
        <div class="d-flex mt-4 pt-4"></div>
        <div class="d-flex mt-5 justify-content-center me-5">
          <a [routerLink]="[artModel.getItem('s25_resize').showUrl]">
            <img src="{{artModel.getItem('s25_resize').thumbUrl}}" width="{{artModel.getItem('s25_resize').width}}"
              height="{{artModel.getItem('s25_resize').height}}">
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <div class="d-flex mt-4 justify-content-start">
          <a [routerLink]="[artModel.getItem('s19_resize').showUrl]">
            <img src="{{artModel.getItem('s19_resize').thumbUrl}}" width="{{artModel.getItem('s19_resize').width}}"
              height="{{artModel.getItem('s19_resize').height}}">
          </a>
        </div>
        <div class="d-flex mt-5 pt-5"></div>
        <div class="d-flex mt-4 pt-4 ml-5 pl-5 justify-content-center">
          <a [routerLink]="[artModel.getItem('ilcio_resize').showUrl]">
            <img src="{{artModel.getItem('ilcio_resize').thumbUrl}}" width="{{artModel.getItem('ilcio_resize').width}}"
              height="{{artModel.getItem('ilcio_resize').height}}">
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
