import { ArtModel } from '../model/art-model';
import { OnInit, Directive } from '@angular/core';
import { PublicArtService } from '../services/public-art.service';

@Directive()
export abstract class BaseComponent implements OnInit {

  public artModel: ArtModel;
  
  constructor(private store: string, private service: PublicArtService) {
  }
  ngOnInit(): void {
    this.artModel = this.service.getArtModel(this.store);
  }
}
