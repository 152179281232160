import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublicRoutingModule } from './public-routing.module';
import { NinComponent } from './nin/nin.component';
import { LinksComponent } from './links/links.component';
import { LetteroniComponent } from './letteroni/letteroni.component';
import { HomeComponent } from './home/home.component';
import { ShowComponent } from './show/show.component';
import { EtComponent } from './et/et.component';
import { LoveComponent } from './love/love.component';
import { GudComponent } from './gud/gud.component';
import { SkaebnehjuletComponent } from './skaebnehjulet/skaebnehjulet.component';
import { PortraetterComponent } from './portraetter/portraetter.component';
import { SharedModule } from '../shared/shared.module';
import { NgbPopoverModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  imports: [
    CommonModule,
    PublicRoutingModule,
    SharedModule,
    NgbPopoverModule
  ],
  declarations: [
    NinComponent,
    LinksComponent,
    LetteroniComponent,
    HomeComponent,
    ShowComponent,
    EtComponent,
    LoveComponent,
    GudComponent,
    SkaebnehjuletComponent,
    PortraetterComponent    
  ],
  providers: [],
})
export class PublicModule { }
