import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicArtService } from '../../shared/services/public-art.service';
import { ArtModel } from '../../shared/model/art-model';
import { ArtItemModel } from '../../shared/model/art-item-model';

@Component({
  selector: 'app-show',
  templateUrl: './show.component.html',
  styleUrls: ['./show.component.css']
})
export class ShowComponent implements OnInit {

  public store = '';
  public id = '';
  public index = 0;
  public artModel: ArtModel;
  public items: ArtItemModel[] = [];

  constructor(
    private route: ActivatedRoute,
    private service: PublicArtService) {

    this.route.params.subscribe(params => {
      console.log(params);

      if (params['store']) {
        this.store = params['store'];
      } else {
        console.error('Invalid params. Missing store');
      }
      if (params['id']) {
        this.id = params['id'];
      } else {
        console.error('Invalid params. Missing id');
      }

    });
  }

  ngOnInit() {
    this.artModel = this.service.getArtModel(this.store);
    this.items = this.artModel.items.filter(x => x.key !== 'Aspekter_resize' && x.hide !== true);
    const item = this.items.find(x => x.fileName === this.id);
    this.index = this.items.indexOf(item);
  }
}
