import { Component, OnInit } from '@angular/core';
import { ArtItemModel } from '../../shared/model/art-item-model';
import { PublicArtService } from '../../shared/services/public-art.service';
import { MapDataService } from './map-data.service';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {

  nin = null;
  links = null;
  letteroni = null;
  portraetter = null;
  skaebnehjulet = null;
  et = null;
  love = null;
  gud = null;

  ninPopoverModel: ArtItemModel;
  etPopoverModel: ArtItemModel;
  gudPopoverModel: ArtItemModel;
  lovePopoverModel: ArtItemModel;
  skaebnehjuletPopoverModel: ArtItemModel;
  portraetterPopoverModel: ArtItemModel;

  constructor(
    private mapDataService: MapDataService,
    private artService: PublicArtService) {

  }

  ngOnInit() {
    this.nin = this.mapDataService.getArea("nin");
    this.links = this.mapDataService.getArea("links");
    this.letteroni = this.mapDataService.getArea("letteroni");
    this.portraetter = this.mapDataService.getArea("portraetter");
    this.skaebnehjulet = this.mapDataService.getArea("skaebnehjulet");
    this.et = this.mapDataService.getArea("et");
    this.love = this.mapDataService.getArea("love");
    this.gud = this.mapDataService.getArea("gud");

    this.ninPopoverModel = this.getPopover('nin');
    this.etPopoverModel = this.getPopover('et');
    this.gudPopoverModel = this.getPopover('gud');
    this.lovePopoverModel = this.getPopover('love');
    this.skaebnehjuletPopoverModel = this.getPopover('skaebnehjulet');
    this.portraetterPopoverModel = this.getPopover('portraetter');
  }

  private getPopover(area: string): ArtItemModel {
    const artModel = this.artService.getArtModel(area);
    const mapModel = this.mapDataService.getArea(area);
    return artModel.getItem(mapModel.popupImage);
  }
}
