import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/components/base-component'
import { PublicArtService } from '../../shared/services/public-art.service';

@Component({
  selector: 'app-et',
  templateUrl: './et.component.html',
  styleUrls: ['./et.component.css']
})
export class EtComponent extends BaseComponent {
  constructor(service: PublicArtService) {
    super('et', service);
  }
}
