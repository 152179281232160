import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/components/base-component';
import { PublicArtService } from '../../shared/services/public-art.service';

@Component({
  selector: 'app-love',
  templateUrl: './love.component.html',
  styleUrls: ['./love.component.css']
})
export class LoveComponent extends BaseComponent {
  constructor(service: PublicArtService) {
    super('love', service);
  }
}
