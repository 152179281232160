import { Injectable } from '@angular/core';
import { ArtModel } from '../model/art-model';
import { ItemStatusCode } from '../model/item-status-code';
import { LinkItem } from '../model/link-item';

@Injectable({
  providedIn: 'root'
})
export class PublicArtService {

  private static links: Array<LinkItem> = [
    new LinkItem('en håndfuld åndrige mennesker som hver dag står foran christiansborg'
      + ' i fredelig protest mod at regering gik ind i krigen mod Afghanistan og Irak',
      'http://www.fredsvagt.dk',
      'fredens_vagt_card.jpg', 1),
    new LinkItem('en hen gruppe mennesker gjorde en meget tiltrængt indsigelse'
      + ' da terrorpakken blev vedtaget og holder fast på deres støtte til grupper af frihedskæmpere',
      'http://www.opror.net',
      'opror_card.jpg', 1),
    new LinkItem('billedkunstner', 'http://www.peter-brandt.com', 'peter_brandt_card.jpg', 1),
    new LinkItem('interviews mm', 'http://www.CafeTV.dk', 'caffe_tv_card.jpg', 2),
    new LinkItem('kanon sangskriver m intelligent politisk indsigt',
      'http://www.davidrovics.com',
      'david_rovics_card.jpg', 2),
    new LinkItem('fed dansk reggae',
      'http://www.actiontaken.dk',
      'action_taken_card.jpg', 2),
    new LinkItem('reel information',
      'https://www.democracynow.org',
      'democracy_now_card.jpg', 3),
    new LinkItem('purderous magina records; central for low-fi musik, ren underground. + digte, revolution, bevidsthed.',
      'http://www.pmr.fm',
      'pmr_card.jpg', 3),
    new LinkItem('Jens Galshiøtts flotte arbejde',
      'http://www.aidoh.dk',
      'aidoh_card.jpg', 4),
    new LinkItem('fed udstillingsform & koncept.',
      'http://www.2020visions.dk',
      '2020_vision_card.jpg', 4),
    new LinkItem('Paulina’s galleri i Napoli.',
      'http://www.t293.it',
      't293_card.jpg', 4)
  ];

  private static ninArtModel: ArtModel =
    new ArtModel('nin')
      .addItem('s10a', 'selvprotræt 99', 387, 461, '')
      .addItem('logo', 'logo', 300, 300, 'nin logo.', ItemStatusCode.none, true)
      .addItem('goya', 'pilgrimsfærden til San Isidro af Goya 1820', 538, 163, '')
      .addItem('anno71', 'børne tegning fra 1971', 256, 352, '')
      .addItem('krig', 'mænd i krig', 200, 415, 'halvdelen døde')
      .addItem('jorden', 'jorden', 373, 333, 'vægtegning hos Bijan')
      .addItem('havfrue', 'havfrue', 261, 364, 'statue til en demonstration.');

  private static loveArtModel: ArtModel =
    new ArtModel('love')
      .addItem('Aspekter_resize', 'una storia d\'Amore', 963, 143, '125x913cm olie på lærred', ItemStatusCode.available)
      .addItem('hus_resize', 'vores lille hus på landet', 112, 168, '26x18cm grafik flerfarvettryk', ItemStatusCode.available)
      .addItem('s15a_resize', 'en er oppe, en er nede', 96, 142, '25x17cm grafik, koldnål', ItemStatusCode.sold)
      .addItem('s15b_resize', 'sammen', 160, 108, '17x25cm   grafik, koldnål', ItemStatusCode.available)
      .addItem('s15c_resize', 'completandoci', 128, 106, '17x20cm grafik, koldnål', ItemStatusCode.available)
      .addItem('Spredning_resize', 'blomst i frø', 240, 167, '34x45cm grafik, blødgrund', ItemStatusCode.available)
      .addItem('s9_resize', 'loverman', 352, 237, '6stk 32x32cm tusch på papir', ItemStatusCode.available)
      .addItem('s28a_resize', 'Anna og Cecilie', 288, 219, '60x80cm  olie på lærred', ItemStatusCode.available)
      .addItem('monsteret_resize', 'monsteret som ville elske', 128, 165, '33x25 cm grafik, ætsning', ItemStatusCode.available)
      .addItem('s19_resize', 'when love goes wrong', 368, 287, '157x200cm  acryl på papir, snavset tilstand', ItemStatusCode.available)
      .addItem('s28b_resize', 'pæren', 144, 218, '100x60cm olie på lærred', ItemStatusCode.sold)
      .addItem('s28c_resize', '2 hvidløg', 192, 144, '60x85cm olie på lærred', ItemStatusCode.sold)
      .addItem('s25_resize', 'morgenlys (Alba)', 304, 215, '70x100cm olie på lærred', ItemStatusCode.sold)
      .addItem('ilcio_resize', 'il bacio', 160, 191, '60x50cm olie på lærred', ItemStatusCode.sold);

  private static gudArtModel: ArtModel =
    new ArtModel('gud')
      .addItem('drawing_resize', 'hjertefugl', 144, 120, '30x35cm tusch på papir', ItemStatusCode.sold)
      .addItem('gud_resize', 'materiens mystiske liv', 352, 350, '9stk 32x32cm   oliekridt på papir', ItemStatusCode.available)
      .addItem('Apocalypse_resize', 'apocalypse now', 208, 205, '42x43cm oliekridt på transparent', ItemStatusCode.available)
      .addItem('fims_resize', 'fliks', 96, 85, '28x32cm oliekridt på papir', ItemStatusCode.available)
      .addItem('oejne_resize', 'det uendelige havet i hans øjne', 112, 112, '32x32cm tusch på papir', ItemStatusCode.available)
      .addItem('basquiat_resize', 'ode til Basquiat', 192, 176, '55x60cm oliekridt på transparent', ItemStatusCode.available)
      .addItem('brun_resize', 'brun', 144, 147, '40x37cm oliekridt på papir', ItemStatusCode.available)
      .addItem('lillebasquiat_resize', 'Basquiat', 112, 122, '32x32cm oliekridt på papir', ItemStatusCode.available)
      .addItem('s20_resize', 'collabrazione col nero eller: Horus øje', 448, 349, '157x200cm acryl på papir i snavset tilstand', ItemStatusCode.available)
      .addItem('vrede_resize', 'vrede', 144, 215, '100x70cm acryl på papir', ItemStatusCode.sold)
      .addItem('s11b_resize',
        'golfo di napoli - sjælene stiger ned fra bjerget', 192, 196, '60x60cm olie på lærred', ItemStatusCode.sold)
      .addItem('s11a_resize',
        'golfo di napoli - la petite morte', 192, 192, '60x60cm   olie på lærred', ItemStatusCode.sold)
      .addItem('s10b_resize', 'golfo di napoli - livslyst', 192, 193, '60x60cm olie på lærred', ItemStatusCode.sold)
      .addItem('s10a_resize', 'golfo di napoli - selvportræt', 160, 189, '60x50cm olie på lærred', ItemStatusCode.sold);

  private static etArtModel: ArtModel =
    new ArtModel('et')
      .addItem('2feminine', '2 feminine', 144, 143, '45x45cm olie på lærred', ItemStatusCode.available)
      .addItem('ansigter', 'ansigter fra det fælles land', 368, 292, '19stk ca.42x32cm olie på papir', ItemStatusCode.available)
      .addItem('frieboelger', 'friebølger', 192, 192, '60X60cm olie på lærred', ItemStatusCode.available)
      .addItem('Kaffekande', 'kaffekanden', 112, 121, '20x18cm grafik, blødgrund', ItemStatusCode.available)
      .addItem('solsikken_resize', 'solsikken', 192, 185, '', ItemStatusCode.available)
      .addItem('gylden', 'gylden fisse', 80, 77, '15x18cm oliekridt på papir', ItemStatusCode.available)
      .addItem('s13_resize', '', 64, 105, '', ItemStatusCode.available)
      .addItem('zagg', 'beautyful Zagg', 144, 124, '38x45cm kopi på overhead', ItemStatusCode.available)
      .addItem('floating_resize', 'floating', 160, 133, '42x49cm oliekridt på transparent & sold=*privat', ItemStatusCode.private)
      .addItem('rosa1', 'rosa', 144, 127, '32x32cm oliepastel på transparent & sold=*privat', ItemStatusCode.available)
      .addItem('ryg', 'ryg - efter: borgerne fra Calais, Rodin', 96, 220, '31x15cm grafik,koldnål', ItemStatusCode.available)
      .addItem('havblik', 'havet', 192, 191, '', ItemStatusCode.available)
      .addItem('rosa', 'tegninger', 272, 254, 'oliekridt på papir', ItemStatusCode.available)
      .addItem('hoved',
        'hoved - efter: borgerne fra Calais , Rodin', 112, 199, '60x60cm olie på lærred', ItemStatusCode.sold)
      .addItem('kysset', 'kysset efter skulptur af Rodin', 160, 239, '70x40cm blyant på papir', ItemStatusCode.available)
      .addItem('noegle', 'nøgle - efter: borgerne fra Calais , Rodin', 112, 257, '35x14cm koldnål', ItemStatusCode.available);

  private static portraetterArtModel: ArtModel =
    new ArtModel('portraetter')
      .addItem('s10a', 'selvprotræt 99', 416, 512, '')
      .addItem('s27a_resize', 'selvportræt', 275, 270, '38x34cm olie på lærred', ItemStatusCode.sold)
      .addItem('s27b_resize', 'selvportr', 267, 270, '41x40cm   olie på lærred', ItemStatusCode.sold)
      .addItem('kande_resize', 'kande i 3/4 profil - selvportræt', 233, 380, '40x25cm olie på lærred', ItemStatusCode.sold);

  private static skaebnehjuletArtModel: ArtModel =
    new ArtModel('skaebnehjulet')
      .addItem('hav_resize', 'hav', 224, 256, '60x60cm   olie på lærred', ItemStatusCode.available)
      .addItem('sort_resize', 'sort mellemmand - efter: borgerne fra Calais , Rodin', 83, 208, '33x12cm grafik, ætsning', ItemStatusCode.available)
      .addItem('sortnoegle_resize', 'sort nøgle - efter: borgerne fra Calais , Rodin', 96, 222, '33x15cm grafik, ætsning', ItemStatusCode.available)
      .addItem('udblik5_resize', 'udblik fra Capodimonte nr1', 176, 129, '30x40cm akvarel og blæk på papir', ItemStatusCode.available)
      .addItem('udblik6_resize', 'udblik fra Capodimonte nr2', 176, 113, '30x40cm akvarel og blæk på papir', ItemStatusCode.available)
      .addItem('80er_2_resize', '1', 176, 124, '30x42cm oliekridt på papir', ItemStatusCode.available)
      .addItem('80er_resize', '2', 176, 125, '30x42cm oliekridt på papir', ItemStatusCode.available)
      .addItem('solskitse', 'skitse', 144, 145, '27x26cm grafik', ItemStatusCode.available)
      .addItem('hoved_resize', 'hoved - efter: borgerne fra Calais , Rodin', 128, 222, '33x19cm grafik, ætsning', ItemStatusCode.available)
      .addItem('mand_resize', 'lysmellemmand - efter: borgerne fra Calais , Rodin', 80, 212, '33x13cm grafik, ætsning', ItemStatusCode.available)
      .addItem('danser_resize', 'danse - efter: borgerne fra Calais , Rodin', 112, 205, '33x18cm grafik, ætsning', ItemStatusCode.available)
      .addItem('s23_resize', 'om solen', 368, 400, '4stk 70x60cm olie på lærred', ItemStatusCode.sold)
      .addItem('udblik2_resize', 'udblik fra Capodimonte nr6', 176, 112, '30x40cm akvarel og blæk på papir', ItemStatusCode.available)
      .addItem('firkant_resize', 'brun firkant', 160, 151, '34x36cm oliekridt på papir', ItemStatusCode.available)
      .addItem('lille_resize', 'lille fyr', 112, 155, '32x24cm oliekridt på papir', ItemStatusCode.available)
      .addItem('udblik7_resize', 'udblik fra Capodimonte nr7', 176, 112, '30x40cm akvarel og blæk på papir', ItemStatusCode.available)
      .addItem('udblik1_resize', 'udblik fra Capodimonte nr5', 176, 112, '30x40cm akvarel og blæk på papir', ItemStatusCode.available)
      .addItem('udblik4_resize', 'udblik fra Capodimonte nr4', 176, 112, '30x40cm akvarel og blæk på papir', ItemStatusCode.available)
      .addItem('udblik3_resize', 'udblik fra Capodimonte nr3', 176, 112, '30x40cm akvarel og blæk på papir', ItemStatusCode.available)
      .addItem('s21_resize', '4 abstrakte', 288, 382, '4 stk 70x50cm olie på papir', ItemStatusCode.available);

  constructor() {

  }

  public getLinks(): LinkItem[] {
    return PublicArtService.links;
  }

  public getArtModel(store: string): ArtModel {
    var artModel = new ArtModel(store);

    if (store === 'nin') {
      return PublicArtService.ninArtModel;
    }
    if (store === 'love') {
      return PublicArtService.loveArtModel;
    }
    if (store === 'gud') {
      return PublicArtService.gudArtModel;
    }
    if (store === 'et') {
      return PublicArtService.etArtModel;
    }
    if (store === 'portraetter') {
      return PublicArtService.portraetterArtModel;
    }
    if (store === 'skaebnehjulet') {
      return PublicArtService.skaebnehjuletArtModel;
    }

    return artModel;
  }

}
