import { Component, OnInit } from '@angular/core';
import { LinkItem } from '../../shared/model/link-item';
import { PublicArtService } from '../../shared/services/public-art.service';

@Component({
  selector: 'app-links',
  templateUrl: './links.component.html',
  styleUrls: ['./links.component.css'],
})
export class LinksComponent implements OnInit {

  public links: Array<LinkItem> = [];

  constructor(private service: PublicArtService) { }

  ngOnInit() {
    this.links = this.service.getLinks();
  }

}
