import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class MapDataService {

  private mapData = {
    areas: [
      {
        id: 'nin',
        popupImage: 's10a'
      },
      {
        id: 'links'
      },
      {
        id: 'letteroni',        
      },
      {
        id: 'portraetter',
        popupImage: 's27a_resize'
      },
      {
        id: 'skaebnehjulet',
        popupImage: 's23_resize'
      },
      {
        id: 'et',
        popupImage: 'floating_resize'
      },
      {
        id: 'love',
        popupImage: 's15b_resize'
      },
      {
        id: 'gud',
        popupImage: 's20_resize'
      }
    ]
  }

  constructor() { }

  getArea(id: string) {
    return this.mapData.areas.find(area => area.id === id);
  }
}
