<div class="container-fluid justify-content-start">
  <div class="row sticky-top w-100">
    <div class="col-12 d-flex justify-content-start">
      <a class="header-link" [routerLink]="'/home'">
        home
      </a>
    </div>
  </div>
  <div class="row pl-0">
    <div class="col-md-1 pl-2">
      <div class="row">
        <hr>
      </div>
    </div>
    <div class="col-md-11">
      <div class="row">
        <div class="col-md-6 col-ms-4">
        </div>
        <div class="col-md-6 col-ms-8">
          <div class="container container-fluid text-left">
            <h6>terroni</h6>
            <p class="ml-4 pl-2">di minamevid</p>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-9">
          <div class="row">
            <div class="col-md-6">
              <div class="container text-center">
                <h6 class="text-center">sopra ai tetti</h6>
                <h6 class="text-center">la città</h6>
              </div>
            </div>
            <div class="col-md-6">

            </div>
          </div>
          <div class="row">
            <div class="container text-left">
              <p>
                da dove tutte le persone sembrano piccole<br>
                e il loro fare sembra insignificante<br>
                l’uccelo perdeva il suo occhio di mostro<br>
                e il paessaggio tramutò sotto la sua Montagne Celeste<br>
              </p>
              <p>
                nella notte inaspettatamente si compì<br>
                il miracolo della benevolenza e<br>
                del passato e pure mai più
              </p>
              <p class="text-nowrap">
                erano arrivato gli elefanti i leoni e sopra lo scenario<br>
                volavano gli angeli con le ali d’oro. fuori di vista era il fango e<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                la morta
              </p>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-2">
            </div>
            <div class="col-md-10 container container-fluid text-left">
              <p class="text-left">
                un uomo molto ubriaco camminava<br>
                per la città vuota e buia<br>
                sputtando qua e la lalalalalalala<br>
                fischiava la vie en rose tra le nuvole mentre
              </p>
              <p class="text-center">
                spariva pian piano
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-3">
        </div>
      </div>
      <div class="row mt-5">
        <div class="container text-left text-nowrap">
          <p>oggi ho comprato tre galline<br>
            che mi dicevano;<br>
            <strong>
              dunque siete voi coloro i quali credono al Mondo<br>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              godete brutti<br>
            </strong>
            godete la libertà figli di Dio<br>
            ma non scordatevi di pulire i cessi<br>
            sovrappieni del piscio giallo<br>
            scorrendo sul pavimento<br>
            in un fiume turkese<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            giù per le scale<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            flusciando tutta la città
          </p>
          <p>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            e soltanto..
          </p>
        </div>
      </div>
      <div class="row mt-5">
        <div class="col-md-3">

        </div>
        <div class="col-md-6">
          <p class="text-nowrap">
            &nbsp;&nbsp;&nbsp;
            la prima a
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            destra<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            e dopo il secondo incrocio a
            &nbsp;&nbsp;&nbsp;&nbsp;<br>
            sinistra
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
            <strong>prosegue</strong>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;
            <small>prosegue</small><br>
            al semaforo verde una coccinella<br>
            a puntini neri grandi e <small>piccoli</small><br>
            come pezzi di carta volando qua e la <br>
            sapendo lei arriva fra cinque minuti<br>
            e tu stai sola sola<br>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            non può essere<br>
            ma piuttosto dispersa nell’aria si sentiva
          </p>
          <p class="text-right text-nowrap font-italic">
            leggera leggera
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;
          </p>
        </div>
        <div class="col-md-3">

        </div>
      </div>
    </div>
  </div>
</div>