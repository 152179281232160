<div class="container-fluid justify-content-start">
  <div class="row sticky-top w-100">
    <div class="col-12 d-flex justify-content-start">
      <a class="header-link" [routerLink]="'/home'">
        home
      </a>
    </div>
  </div>
  <div id="content-device">
    <div class="d-flex align-items-center justify-content-center p-2" *ngFor="let item of artModel.items let i = index">
      <a [routerLink]="[item.showUrl]">
        <img class="img-fluid mx-auto d-block" src="{{item.fullSizeUrl}}">
      </a>
    </div>
  </div>
  <div id="content-desktop">
    <div class="d-flex flex-row ml-5 mt-2">
      <div class="col-md-4">
        <div class="row mt-5">
        </div>
        <div class="d-flex justify-content-end m-3">
          <div class="d-flex mt-5 justify-content-end">
            <a [routerLink]="[artModel.getItem('s27b_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('s27b_resize').fullSizeUrl}}"
                width="{{artModel.getItem('s27b_resize').width}}" height="{{artModel.getItem('s27b_resize').height}}">
            </a>
          </div>
        </div>

      </div>
      <div class="col-md-4">
        <div class="row">
          <div class="d-flex justify-content-center">
            <a [routerLink]="[artModel.getItem('s27a_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('s27a_resize').fullSizeUrl}}"
                width="{{artModel.getItem('s27a_resize').width}}" height="{{artModel.getItem('s27a_resize').height}}">
            </a>
          </div>
        </div>
        <div class="row">
          <div class="d-flex justify-content-center mt-5">
            <a [routerLink]="[artModel.getItem('kande_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('kande_resize').fullSizeUrl}}"
                width="{{artModel.getItem('kande_resize').width}}" height="{{artModel.getItem('kande_resize').height}}">
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="d-flex p-4">
          <div class="d-flex justify-content-start pt-5">
            <a [routerLink]="[artModel.getItem('s10a').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('s10a').fullSizeUrl}}"
                width="{{artModel.getItem('s10a').width}}" height="{{artModel.getItem('s10a').height}}">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
