<div class="justify-content-start">
  <div class="d-flex sticky-top w-100">
    <div class="col-12 d-flex justify-content-start">
      <a class="header-link" [routerLink]="'/home'">
        home
      </a>
    </div>
  </div>
  <div id="content-device">
    <div class="d-flex align-items-center justify-content-center p-2" *ngFor="let item of artModel.items let i = index">
      <a [routerLink]="[item.showUrl]">
        <img class="img-fluid mx-auto d-block" src="{{item.fullSizeUrl}}">
      </a>
    </div>
  </div>
  <div id="content-desktop">
    <div class="d-flex flex-row p-2 mt-2">
      <div class="col-md-4 d-flex justify-content-center">
        <div>
          <a [routerLink]="[artModel.getItem('hav_resize').showUrl]">
            <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('hav_resize').thumbUrl}}"
              width="{{artModel.getItem('hav_resize').width}}" height="{{artModel.getItem('hav_resize').height}}">
          </a>
        </div>
      </div>
      <div class="col-md-3 d-flex flex-row justify-content-start">
        <div class="d-flex mt-2">
          <div class="col-md-5 m-2">
            <a [routerLink]="[artModel.getItem('sort_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('sort_resize').thumbUrl}}"
                width="{{artModel.getItem('sort_resize').width}}" height="{{artModel.getItem('sort_resize').height}}">
            </a>
          </div>
          <div class="col-md-7 m-2">
            <a [routerLink]="[artModel.getItem('sortnoegle_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('sortnoegle_resize').thumbUrl}}"
                width="{{artModel.getItem('sortnoegle_resize').width}}"
                height="{{artModel.getItem('sortnoegle_resize').height}}">
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="d-flex justify-content-start">
          <a [routerLink]="[artModel.getItem('udblik5_resize').showUrl]">
            <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('udblik5_resize').thumbUrl}}"
              width="{{artModel.getItem('udblik5_resize').width}}"
              height="{{artModel.getItem('udblik5_resize').height}}">
          </a>
        </div>
        <div class="d-flex d-flex justify-content-start mt-3">
          <a [routerLink]="[artModel.getItem('udblik6_resize').showUrl]">
            <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('udblik6_resize').thumbUrl}}"
              width="{{artModel.getItem('udblik6_resize').width}}"
              height="{{artModel.getItem('udblik6_resize').height}}">
          </a>
        </div>
      </div>
      <div class="col-md-1">
      </div>
    </div>
    <div class="d-flex justify-content-start mt-5">
      <div class="col-md-6">
        <div class="d-flex">
          <div class="col-md-7 d-flex flex-column justify-content-center">
            <div class="justify-content-center">
              <a [routerLink]="[artModel.getItem('80er_2_resize').showUrl]">
                <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('80er_2_resize').thumbUrl}}"
                  width="{{artModel.getItem('80er_2_resize').width}}"
                  height="{{artModel.getItem('80er_2_resize').height}}">
              </a>
            </div>
            <div class="justify-content-center">
              <a [routerLink]="[artModel.getItem('80er_resize').showUrl]">
                <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('80er_resize').thumbUrl}}"
                  width="{{artModel.getItem('80er_resize').width}}" height="{{artModel.getItem('80er_resize').height}}">
              </a>
            </div>
          </div>
          <div class="col-md-5 d-flex mt-4 pr-4 justify-content-center">
            <a [routerLink]="[artModel.getItem('solskitse').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('solskitse').thumbUrl}}"
                width="{{artModel.getItem('solskitse').width}}" height="{{artModel.getItem('solskitse').height}}">
            </a>
          </div>
        </div>
        <div class="d-flex mt-5">
          <div class="col-md-1 d-flex justify-content-end">
          </div>
          <div class="col-md-4 d-flex justify-content-end">
            <a [routerLink]="[artModel.getItem('hoved_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('hoved_resize').thumbUrl}}"
                width="{{artModel.getItem('hoved_resize').width}}" height="{{artModel.getItem('hoved_resize').height}}">
            </a>
          </div>
          <div class="col-md-3 d-flex justify-content-center">
            <a [routerLink]="[artModel.getItem('mand_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('mand_resize').thumbUrl}}"
                width="{{artModel.getItem('mand_resize').width}}" height="{{artModel.getItem('mand_resize').height}}">
            </a>
          </div>
          <div class="col-md-4 d-flex justify-content-start">
            <a [routerLink]="[artModel.getItem('danser_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('danser_resize').thumbUrl}}"
                width="{{artModel.getItem('danser_resize').width}}"
                height="{{artModel.getItem('danser_resize').height}}">
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6 d-flex justify-content-start pl-4">
        <a [routerLink]="[artModel.getItem('s23_resize').showUrl]">
          <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('s23_resize').thumbUrl}}"
            width="{{artModel.getItem('s23_resize').width}}" height="{{artModel.getItem('s23_resize').height}}">
        </a>
      </div>
    </div>
    <div class="d-flex mt-2">
      <div class="col-md-6 mt-5">
        <div class="d-flex pb-3">
          <div class="col-md-12">
            <a [routerLink]="[artModel.getItem('udblik2_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('udblik2_resize').thumbUrl}}"
                width="{{artModel.getItem('udblik2_resize').width}}"
                height="{{artModel.getItem('udblik2_resize').height}}">
            </a>
          </div>
        </div>
        <div class="d-flex mb-3">
          <div class="col-md-6 d-flex justify-content-end">
            <a [routerLink]="[artModel.getItem('udblik7_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('udblik7_resize').thumbUrl}}"
                width="{{artModel.getItem('udblik7_resize').width}}"
                height="{{artModel.getItem('udblik7_resize').height}}">
            </a>
          </div>
          <div class="col-md-6 d-flex justify-content-start">
            <a [routerLink]="[artModel.getItem('udblik1_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('udblik1_resize').thumbUrl}}"
                width="{{artModel.getItem('udblik1_resize').width}}"
                height="{{artModel.getItem('udblik1_resize').height}}">
            </a>
          </div>
        </div>
        <div class="d-flex">
          <div class="col-md-6 d-flex justify-content-end">
            <a [routerLink]="[artModel.getItem('udblik4_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('udblik4_resize').thumbUrl}}"
                width="{{artModel.getItem('udblik4_resize').width}}"
                height="{{artModel.getItem('udblik4_resize').height}}">
            </a>
          </div>
          <div class="col-md-6 d-flex justify-content-start">
            <a [routerLink]="[artModel.getItem('udblik3_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('udblik3_resize').thumbUrl}}"
                width="{{artModel.getItem('udblik3_resize').width}}"
                height="{{artModel.getItem('udblik3_resize').height}}">
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex mt-2">
          <div class="col-md-5 d-flex justify-content-start pl-5">
            <a [routerLink]="[artModel.getItem('firkant_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('firkant_resize').thumbUrl}}"
                width="{{artModel.getItem('firkant_resize').width}}"
                height="{{artModel.getItem('firkant_resize').height}}">
            </a>
          </div>
          <div class="col-md-7 d-flex justify-content-start">
            <a [routerLink]="[artModel.getItem('lille_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('lille_resize').thumbUrl}}"
                width="{{artModel.getItem('lille_resize').width}}" height="{{artModel.getItem('lille_resize').height}}">
            </a>
          </div>
        </div>
        <div class="d-flex justify-content-start mt-4 pl-5">
          <div class="col-md-7 mt-4 d-flex justify-content-center pr-5">
            <a [routerLink]="[artModel.getItem('s21_resize').showUrl]">
              <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('s21_resize').thumbUrl}}"
                width="{{artModel.getItem('s21_resize').width}}" height="{{artModel.getItem('s21_resize').height}}">
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
