import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NinComponent } from './nin/nin.component';
import { LinksComponent } from './links/links.component';
import { LetteroniComponent } from './letteroni/letteroni.component';
import { HomeComponent } from './home/home.component';
import { ShowComponent } from './show/show.component';
import { EtComponent } from './et/et.component';
import { LoveComponent } from './love/love.component';
import { GudComponent } from './gud/gud.component';
import { SkaebnehjuletComponent } from './skaebnehjulet/skaebnehjulet.component';
import { PortraetterComponent } from './portraetter/portraetter.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'nin', component: NinComponent },
  { path: 'links', component: LinksComponent },
  { path: 'letteroni', component: LetteroniComponent },
  { path: 'et', component: EtComponent },
  { path: 'love', component: LoveComponent },
  { path: 'gud', component: GudComponent },
  { path: 'skaebnehjulet', component: SkaebnehjuletComponent },
  { path: 'portraetter', component: PortraetterComponent },
  { path: 'show/:store/:id', component: ShowComponent },
  { path: '**', redirectTo: 'home' }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicRoutingModule { }
