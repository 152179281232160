import { ItemStatusCode } from "./item-status-code";

export class ArtItemModel {

  constructor(private root: string) {
  }

  private _key: string;
  public get key(): string {
    return this._key;
  }
  public set key(v: string) {
    this._key = v;
  }

  public get fileName(): string {
    return this.key + '.jpg';
  }
  public get thumbUrl(): string {
    return '../../assets/images/art/' + this.root + '/' + this.key + '-thumb.jpg';
  }

  public get showUrl(): string {
    return '/show/' + this.root + '/' + this.key + '.jpg';
  }

  public get fullSizeUrl(): string {
    return '../../assets/images/art/' + this.root + '/' + this.fileName;
  }

  private _title: string;
  public get title(): string {
    return this._title;
  }
  public set title(v: string) {
    this._title = v;
  }

  private _width: number;
  public get width(): number {
    return this._width;
  }
  public set width(v: number) {
    this._width = v;
  }

  private _height: number;
  public get height(): number {
    return this._height;
  }
  public set height(v: number) {
    this._height = v;
  }

  private _description: string;
  public get description(): string {
    return this._description;
  }
  public set description(v: string) {
    this._description = v;
  }

  private _status: ItemStatusCode;
  public get status(): ItemStatusCode {
    return this._status;
  }
  public set status(v: ItemStatusCode) {
    this._status = v;
  }

  private _hide: boolean;
  public get hide(): boolean {
    return this._hide;
  }
  public set hide(v: boolean) {
    this._hide = v;
  }

}
