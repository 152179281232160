import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExternalRedirectDirective } from './directives/external-redirect.directive';
import { PublicArtService } from './services/public-art.service';
import { GroupByPipe } from './pipes/group-by.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    ExternalRedirectDirective,
    GroupByPipe
  ],
  providers: [PublicArtService, GroupByPipe],
  exports: [GroupByPipe]
})
export class SharedModule { }
