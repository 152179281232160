<div class="container-fluid justify-content-center">
  <div class="d-flex sticky-top w-100">
    <div class="col-12 d-flex justify-content-start">
      <a class="header-link" [routerLink]="'/home'">
        home
      </a>
    </div>
  </div>
  <div id="content-device">
    <div class="d-flex align-items-center justify-content-center p-2" *ngFor="let item of artModel.showableItems() let i = index">
      <a [routerLink]="[item.showUrl]">
        <img class="img-fluid mx-auto d-block" src="{{item.fullSizeUrl}}">
      </a>
    </div>
  </div>
  <div id="content-desktop">
    <div class="d-flex align-items-center">
      <div class="col-md-4">
        <div>
          <a [routerLink]="[artModel.getItem('2feminine').showUrl]">
            <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('2feminine').thumbUrl}}"
              width="{{artModel.getItem('2feminine').width}}" height="{{artModel.getItem('2feminine').height}}">
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <div>
          <a [routerLink]="[artModel.getItem('ansigter').showUrl]">
            <img src="{{artModel.getItem('ansigter').thumbUrl}}" width="{{artModel.getItem('ansigter').width}}"
              height="{{artModel.getItem('ansigter').height}}">
          </a>
        </div>
      </div>
      <div class="col-md-4">
        <a [routerLink]="[artModel.getItem('frieboelger').showUrl]">
          <img class="mx-auto d-block" src="{{artModel.getItem('frieboelger').thumbUrl}}"
            width="{{artModel.getItem('frieboelger').width}}" height="{{artModel.getItem('frieboelger').height}}">
        </a>
      </div>
    </div>
    <div class="d-flex mt-4">
      <div class="col-md-3 pl-5">
        <div class="d-flex justify-content-center">
          <a [routerLink]="[artModel.getItem('Kaffekande').showUrl]">
            <img class="mx-auto d-md-block img-fluid" src="{{artModel.getItem('Kaffekande').thumbUrl}}"
              width="{{artModel.getItem('Kaffekande').width}}" height="{{artModel.getItem('Kaffekande').height}}">
          </a>
        </div>
        <div class="d-flex flex-column mt-5">
          <div class="d-flex justify-content-center">
            <div class="ml-5">
              <a [routerLink]="[artModel.getItem('solsikken_resize').showUrl]">
                <img src="{{artModel.getItem('solsikken_resize').thumbUrl}}"
                  width="{{artModel.getItem('solsikken_resize').width}}"
                  height="{{artModel.getItem('solsikken_resize').height}}">
              </a>
            </div>
          </div>
          <div class="d-flex mt-5 justify-content-center">
            <div>
              <a [routerLink]="[artModel.getItem('gylden').showUrl]">
                <img src="{{artModel.getItem('gylden').thumbUrl}}" width="{{artModel.getItem('gylden').width}}"
                  height="{{artModel.getItem('gylden').height}}">
              </a>
            </div>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="p-4"><hr/></div>
          <div class="d-flex mt-1 p-3 justify-content-left">
            <div>
              <a [routerLink]="[artModel.getItem('s13_resize').showUrl]">
                <img src="{{artModel.getItem('s13_resize').thumbUrl}}" width="{{artModel.getItem('s13_resize').width}}"
                  height="{{artModel.getItem('s13_resize').height}}">
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3 pt-5 pl-5">
        <div class="d-flex">
          <a [routerLink]="[artModel.getItem('zagg').showUrl]">
            <img src="{{artModel.getItem('zagg').thumbUrl}}" width="{{artModel.getItem('zagg').width}}"
              height="{{artModel.getItem('zagg').height}}">
          </a>
        </div>
        <div class="d-flex mt-5">
          <hr>
        </div>
        <div class="d-flex">
          <a [routerLink]="[artModel.getItem('floating_resize').showUrl]">
            <img src="{{artModel.getItem('floating_resize').thumbUrl}}"
              width="{{artModel.getItem('floating_resize').width}}"
              height="{{artModel.getItem('floating_resize').height}}">
          </a>
        </div>
        <div class="d-flex mt-5 mr-4 justify-content-start">
          <div>
            <a [routerLink]="[artModel.getItem('rosa1').showUrl]">
              <img src="{{artModel.getItem('rosa1').thumbUrl}}" width="{{artModel.getItem('rosa1').width}}"
                height="{{artModel.getItem('rosa1').height}}">
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-2 content-top mt-5 pl-4">
        <div class="d-flex">
          <a [routerLink]="[artModel.getItem('ryg').showUrl]">
            <img src="{{artModel.getItem('ryg').thumbUrl}}" width="{{artModel.getItem('ryg').width}}"
              height="{{artModel.getItem('ryg').height}}">
          </a>
        </div>
        <div class="d-flex mt-2">
          <div class="mt-5">
            <a [routerLink]="[artModel.getItem('havblik').showUrl]">
              <img src="{{artModel.getItem('havblik').thumbUrl}}" width="{{artModel.getItem('havblik').width}}"
                height="{{artModel.getItem('havblik').height}}">
            </a>
          </div>
        </div>
      </div>
      <div class="col-md-4 justify-content-start">
        <div class="d-flex">
          <div>
            <a [routerLink]="[artModel.getItem('rosa').showUrl]">
              <img src="{{artModel.getItem('rosa').thumbUrl}}" width="{{artModel.getItem('rosa').width}}"
                height="{{artModel.getItem('rosa').height}}">
            </a>
          </div>
        </div>
        <div class="d-flex flex-row">
          <div class="p-5"></div>
          <div class="d-flex mt-5 justify-content-center">
            <div class="mt-5">
              <a [routerLink]="[artModel.getItem('hoved').showUrl]">
                <img src="{{artModel.getItem('hoved').thumbUrl}}" width="{{artModel.getItem('hoved').width}}"
                  height="{{artModel.getItem('hoved').height}}">
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex mt-5">
      <div class="col-md-3">
      </div>
      <div class="col-md-3">
        <div>
          <a [routerLink]="[artModel.getItem('kysset').showUrl]">
            <img src="{{artModel.getItem('kysset').thumbUrl}}" width="{{artModel.getItem('kysset').width}}"
              height="{{artModel.getItem('kysset').height}}">
          </a>
        </div>
      </div>
      <div class="col-md-3">
        <div>
          <a [routerLink]="[artModel.getItem('noegle').showUrl]">
            <img src="{{artModel.getItem('noegle').thumbUrl}}" width="{{artModel.getItem('noegle').width}}"
              height="{{artModel.getItem('noegle').height}}">
          </a>
        </div>
      </div>
      <div class="col-md-3">
      </div>
    </div>
  </div>
</div>
