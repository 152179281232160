export class LinkItem {

    constructor(description: string, url: string, imageUrl: string, groupIndex: number) {
        this._title = url.replace('http://', '').replace('https://', '');
        this._url = url;
        this._description = description;
        this._imageUrl = imageUrl;
        this._groupIndex = groupIndex;
    }

    private _groupIndex: number;
    public get groupIndex(): number {
        return this._groupIndex;
    }

    private _title: string;
    public get title(): string {
        return this._title;
    }

    private _description: string;
    public get description(): string {
        return this._description;
    }

    private _url: string;
    public get url(): string {
        return this._url;
    }

    private _imageUrl: string;
    public get imageUrl(): string {
        return '../../assets/images/art/links/' + this._imageUrl;
    }
}
