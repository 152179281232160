<div name="nin" class="container-fluid justify-content-start">
  <div class="d-flex sticky-top w-100">
    <div class="col-12 d-flex justify-content-start">
      <a class="header-link" [routerLink]="'/home'">
        home
      </a>
    </div>
  </div>
  <div id="content-device">
    <div class="d-flex align-items-center justify-content-center p-2" *ngFor="let item of artModel.showableItems() let i = index">
      <a [routerLink]="[item.showUrl]">
        <img class="img-fluid mx-auto d-block" src="{{item.fullSizeUrl}}">
      </a>
    </div>
  </div>
  <div id="content-desktop">
    <div class="d-flex">
      <div class="col-md-3">
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex justify-content-end">
          <a [routerLink]="[artModel.getItem('anno71').showUrl]">
            <img src="{{artModel.getItem('anno71').fullSizeUrl}}" width="{{artModel.getItem('anno71').width}}"
              height="{{artModel.getItem('anno71').height}}">
          </a>
        </div>
      </div>
      <div class="col-md-6">
        <div class="d-flex justify-content-center me-3">
          <a [routerLink]="[artModel.getItem('s10a').showUrl]">
            <img src="{{artModel.getItem('s10a').fullSizeUrl}}" width="{{artModel.getItem('s10a').width}}"
              height="{{artModel.getItem('s10a').height}}">
          </a>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex justify-content-center me-3">
          <img src="../../assets/images/art/nin/nin.jpg" width="67" height="49">
        </div>
        <div>
          <div class="d-flex justify-content-center nin-contact me-3">
            nin&#64;nin.art
          </div>
          <div class="d-flex justify-content-center nin-contact me-3">
            +45 30221200
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex">
          <hr>
        </div>
        <div class="d-flex justify-content-center me-5 pe-4">
          <a [routerLink]="[artModel.getItem('havfrue').showUrl]">
            <img src="{{artModel.getItem('havfrue').fullSizeUrl}}" width="{{artModel.getItem('havfrue').width}}"
              height="{{artModel.getItem('havfrue').height}}">
          </a>
        </div>
      </div>
    </div>
    <div class="d-flex p-4">

    </div>
    <div class="d-flex">
      <div class="col-md-3"></div>
      <div class="d-flex flex-column col-md-2 justify-content-center">
        <div class="d-flex justify-content-center">
          <a [routerLink]="[artModel.getItem('goya').showUrl]">
            <img src="{{artModel.getItem('goya').fullSizeUrl}}" width="{{artModel.getItem('goya').width}}"
              height="{{artModel.getItem('goya').height}}">
          </a>
        </div>
        <div class="d-flex justify-content-center p-5">
          <a [routerLink]="[artModel.getItem('krig').showUrl]">
            <img src="{{artModel.getItem('krig').fullSizeUrl}}" width="{{artModel.getItem('krig').width}}"
              height="{{artModel.getItem('krig').height}}">
          </a>
        </div>
      </div>
      <div class="col-md-2 d-flex justify-content-end">
      </div>
      <div class="col-md-5 d-flex justify-content-start ms-3 p-3">
        <a [routerLink]="[artModel.getItem('jorden').showUrl]">
          <img src="{{artModel.getItem('jorden').fullSizeUrl}}" width="{{artModel.getItem('jorden').width}}"
            height="{{artModel.getItem('jorden').height}}">
        </a>
      </div>
    </div>
  </div>
</div>
