import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/components/base-component';
import { PublicArtService } from '../../shared/services/public-art.service';

@Component({
  selector: 'app-nin',
  templateUrl: './nin.component.html',
  styleUrls: ['./nin.component.css']
})
export class NinComponent extends BaseComponent implements OnInit {
  constructor(service: PublicArtService) {
    super('nin', service);
  }
}
