import { ArtItemModel } from './art-item-model';
import { ItemStatusCode } from './item-status-code';

export class ArtModel {
  public items: ArtItemModel[] = [];
  public statusCode = ItemStatusCode;

  constructor(public root: string) { }

  public addItem(
    key: string,
    title: string,
    width: number,
    height: number,
    description: string,
    status: ItemStatusCode = ItemStatusCode.none,
    hide: boolean = false): ArtModel {

    const item = new ArtItemModel(this.root);
    item.key = key;
    item.title = title;
    item.width = width;
    item.height = height;
    item.description = description;
    item.status = status;
    item.hide = hide;
    this.items.push(item);
    return this;
  }

  public getItem(key: string): ArtItemModel {
    const item = this.items.find(x => x.key === key);
    return item;
  }

  public showableItems(): ArtItemModel[] {
    return this.items.filter(x => x.hide !== true);
  }
}
