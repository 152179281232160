<div class='d-flex align-items-center justify-content-center min-vh-100'>
  <ng-template #ninPopContent>
    <img class="mx-auto d-md-block img-fluid" src="{{ninPopoverModel.fullSizeUrl}}" width="100px">
  </ng-template>
  <ng-template #etPopContent>
    <img class="mx-auto d-md-block img-fluid" src="{{etPopoverModel.thumbUrl}}" width="100px">
  </ng-template>
  <ng-template #gudPopContent>
    <img class="mx-auto d-md-block img-fluid" src="{{gudPopoverModel.thumbUrl}}" width="100px">
  </ng-template>
  <ng-template #lovePopContent>
    <img class="mx-auto d-md-block img-fluid" src="{{lovePopoverModel.thumbUrl}}" width="100px">
  </ng-template>
  <ng-template #skaebnehjuletPopContent>
    <img class="mx-auto d-md-block img-fluid" src="{{skaebnehjuletPopoverModel.thumbUrl}}" width="100px">
  </ng-template>
  <ng-template #portraetterPopContent>
    <img class="mx-auto d-md-block img-fluid" src="{{portraetterPopoverModel.thumbUrl}}" width="100px">
  </ng-template>
  <svg name="layer1" id="content-device" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="-60 0 700 680"
    xml:space="preserve" preserveAspectRatio="xMidYMid slice">
    <image id="nin" width="100%" height="100%" x="0" y="0" xlink:href="../assets/images/front-page.jpg" />
    <circle class="circle" [routerLink]="['/et']" fill="#E5C7DF" cx="245" cy="445" r="30" cursor="pointer"
      pointer-events="visible" container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%">
      <animate attributeName="fill" dur="1000ms" to="transparent" fill="freeze" />
      <animate attributeName="stroke" dur="1000ms" from="#E5C7DF" to="transparent" fill="freeze" />
    </circle>
    <circle [routerLink]="['/love']" fill="#E5C7DF" cx="250" cy="375" r="30" cursor="pointer" pointer-events="visible"
      container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%">
      <animate attributeName="fill" dur="1000ms" to="transparent" fill="freeze" />
      <animate attributeName="stroke" dur="1000ms" from="#E5C7DF" to="transparent" fill="freeze" />
    </circle>
    <circle [routerLink]="['/gud']" fill="#E5C7DF" cx="335" cy="330" r="30" cursor="pointer" pointer-events="visible"
      container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%">
      <animate attributeName="fill" dur="1000ms" to="transparent" fill="freeze" />
      <animate attributeName="stroke" dur="1000ms" from="#E5C7DF" to="transparent" fill="freeze" />
    </circle>
    <circle [routerLink]="['/skaebnehjulet']" fill="#E5C7DF" cx="400" cy="300" r="30" cursor="pointer"
      pointer-events="visible" container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%">
      <animate attributeName="fill" dur="1000ms" to="transparent" fill="freeze" />
      <animate attributeName="stroke" dur="1000ms" from="#E5C7DF" to="transparent" fill="freeze" />
    </circle>
    <circle [routerLink]="['/portraetter']" fill="#E5C7DF" cx="395" cy="420" r="30" cursor="pointer"
      pointer-events="visible" container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%">
      <animate attributeName="fill" dur="1000ms" to="transparent" fill="freeze" />
      <animate attributeName="stroke" dur="1000ms" from="#E5C7DF" to="transparent" fill="freeze" />
    </circle>
    <svg id="signature-device" name="signatureLayer" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 60 60" x="135" y="-5" width="35" height="100%"
      preserveAspectRatio="xMinYMax meet">
      <image id="signature" width="100%" height="100%" x="0" y="0" xlink:href="../assets/images/signature.jpg" />
      <circle [routerLink]="['/nin']" fill="#E5C7DF" cx="30" cy="28" r="30" cursor="pointer" pointer-events="visible"
        container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%">
        <animate attributeName="fill" dur="2000ms" to="transparent" fill="freeze" />
        <animate attributeName="stroke" dur="2000ms" from="#E5C7DF" to="transparent" fill="freeze" />
      </circle>
    </svg>
    <svg id="signature-ipad" name="signatureLayer" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 60 60" x="80" y="-10" width="35" height="100%"
      preserveAspectRatio="xMinYMax meet">
      <image id="signature" width="100%" height="100%" x="0" y="0" xlink:href="../assets/images/signature.jpg" />
      <circle [routerLink]="['/nin']" fill="#E5C7DF" cx="30" cy="30" r="30" cursor="pointer" pointer-events="visible"
        container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%">
        <animate attributeName="fill" dur="2000ms" to="transparent" fill="freeze" />
        <animate attributeName="stroke" dur="2000ms" from="#E5C7DF" to="transparent" fill="freeze" />
      </circle>
    </svg>
    <svg id="signature-small-device" name="signatureLayer" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 60 60" x="150" y="-5" width="35" height="100%"
      preserveAspectRatio="xMinYMax meet">
      <image id="signature" width="100%" height="100%" x="0" y="0" xlink:href="../assets/images/signature.jpg" />
      <circle [routerLink]="['/nin']" fill="#E5C7DF" cx="30" cy="28" r="30" cursor="pointer" pointer-events="visible"
        container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%">
        <animate attributeName="fill" dur="2000ms" to="transparent" fill="freeze" />
        <animate attributeName="stroke" dur="2000ms" from="#E5C7DF" to="transparent" fill="freeze" />
      </circle>
    </svg>
  </svg>
  <svg name="layer1" id="content-desktop" version="1.1" xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="100%" height="100%" viewBox="0 0 800 756"
    xml:space="preserve" preserveAspectRatio="xMidYMid meet">
    <image id="nin" width="100%" height="100%" x="0" y="0" xlink:href="../assets/images/front-page.jpg" />
    <circle class="circle" [routerLink]="['/et']" fill="#E5C7DF" cx="280" cy="500" r="30" cursor="pointer"
      pointer-events="visible" container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%"
      [ngbPopover]="etPopContent" triggers="mouseenter:mouseleave" placement="top">
      <animate attributeName="fill" dur="1000ms" to="transparent" fill="freeze" />
      <animate attributeName="stroke" dur="1000ms" from="#E5C7DF" to="transparent" fill="freeze" />
    </circle>
    <circle [routerLink]="['/love']" fill="#E5C7DF" cx="282" cy="420" r="30" cursor="pointer" pointer-events="visible"
      container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%" [ngbPopover]="lovePopContent"
      triggers="mouseenter:mouseleave" placement="top" [closeDelay]="300">
      <animate attributeName="fill" dur="1000ms" to="transparent" fill="freeze" />
      <animate attributeName="stroke" dur="1000ms" from="#E5C7DF" to="transparent" fill="freeze" />
    </circle>
    <circle [routerLink]="['/gud']" fill="#E5C7DF" cx="382" cy="366" r="30" cursor="pointer" pointer-events="visible"
      container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%" [ngbPopover]="gudPopContent"
      triggers="mouseenter:mouseleave" placement="top" [closeDelay]="300">
      <animate attributeName="fill" dur="1000ms" to="transparent" fill="freeze" />
      <animate attributeName="stroke" dur="1000ms" from="#E5C7DF" to="transparent" fill="freeze" />
    </circle>
    <circle [routerLink]="['/skaebnehjulet']" fill="#E5C7DF" cx="460" cy="328" r="30" cursor="pointer"
      pointer-events="visible" container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%"
      [ngbPopover]="skaebnehjuletPopContent" triggers="mouseenter:mouseleave" placement="top" [closeDelay]="300">
      <animate attributeName="fill" dur="1000ms" to="transparent" fill="freeze" />
      <animate attributeName="stroke" dur="1000ms" from="#E5C7DF" to="transparent" fill="freeze" />
    </circle>
    <circle [routerLink]="['/portraetter']" fill="#E5C7DF" cx="455" cy="470" r="30" cursor="pointer"
      pointer-events="visible" container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%"
      [ngbPopover]="portraetterPopContent" triggers="mouseenter:mouseleave" placement="top" [closeDelay]="300">
      <animate attributeName="fill" dur="1000ms" to="transparent" fill="freeze" />
      <animate attributeName="stroke" dur="1000ms" from="#E5C7DF" to="transparent" fill="freeze" />
    </circle>
    <svg name="signatureLayer" version="1.1" xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink" x="50" y="715" viewBox="0 0 100 100" width="70" height="30">
      <image id="signature" width="100%" height="100%" x="0" y="0" xlink:href="../assets/images/signature.jpg" />
      <circle [routerLink]="['/nin']" fill="#E5C7DF" cx="45" cy="50" r="50" cursor="pointer" pointer-events="visible"
        container="body" opacity="70%" stroke-width="5px" stroke-opacity="10%"  [ngbPopover]="ninPopContent"
        triggers="mouseenter:mouseleave" placement="top" [closeDelay]="300">
        <animate attributeName="fill" dur="2000ms" to="transparent" fill="freeze" />
        <animate attributeName="stroke" dur="2000ms" from="#E5C7DF" to="transparent" fill="freeze" />
      </circle>
    </svg>
  </svg>
</div>