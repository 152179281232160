<div name="slideShow" class="position-relative justify-content-center">
  <div class="row justify-content-center">
    <div class="row sticky-top position-absolute w-100">
      <div class="col-6 d-flex justify-content-start">
        <a class="header-link" [routerLink]="'/home'">
          home
        </a>
      </div>
      <div class="col-6 d-flex justify-content-end">
        <a class="header-link" [routerLink]="'/' + [artModel.root]">
          x
        </a>
      </div>
    </div>
    <div id="art" class="carousel slide" data-bs-ride="carousel" data-bs-interval="false">
      <!-- The slideshow -->
      <div class="carousel-inner">
        <div class="carousel-item" *ngFor="let item of items let i = index" [ngClass]="{'active' : i == index}"
          data-bs-interval="20000">
          <div class="d-flex align-items-center justify-content-center min-vh-100">
            <a [routerLink]="'/' + [artModel.root]">
              <img class="img-fluid mx-auto d-block w-100"
                src="../../assets/images/art/{{this.store}}/{{item.key}}.jpg">
            </a>
          </div>
        </div>
      </div>
    </div>
    <!-- Left and right controls -->
    <div class="carousel-control">
      <a class="carousel-control-prev" role="button" data-bs-target="#art" data-bs-slide="prev">
        <span class="carousel-control-prev-icon"></span>
      </a>
      <a class="carousel-control-next" role="button" data-bs-target="#art" data-bs-slide="next">
        <span class="carousel-control-next-icon"></span>
      </a>
    </div>
  </div>
</div>
